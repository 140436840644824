
export default {
	props: {
		title: {
			type: String,
			required: true,
		},
		items: {
			type: Array,
			required: true,
		},
		loading: {
			type: Boolean,
			default: false,
		},
	},
}
